@media screen and (min-width: 1024px) {
	.container {
		.infoContainer {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			font-family: 'Raleway', sans-serif;
			padding-top: 2%;
			padding-bottom: 2%;
			font-size: 0.9rem;
		}
		.logo {
			width: 160px;
			height: 120px;
		}

		.cardTypeImg {
			padding-top: 5%;
			width: 200px;
		}

		.logoContainer {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
		}
	}
}
.anpcContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding-top: 1%;

	> a > img {
		width: 150px;
	}
}

@media screen and (max-width: 1023px) {
	.container {
		display: flex;
		flex-direction: column;
		font-family: 'Raleway', sans-serif;
		padding-top: 2%;
		padding-bottom: 2%;
		font-size: 0.9rem;
		width: 90vw;
		padding-left: 5%;
		.logo {
			width: 160px;
			height: 120px;
		}
		.anpcContainer {
			display: flex;
			flex-direction: column;
			> a > img {
				width: 150px;
			}
		}
		.cardTypeImg {
			padding-top: 5%;
			padding-bottom: 5%;

			width: 200px;
		}
		.contactPadding {
			padding-top: 3%;
		}
	}
}
