@media screen and (min-width: 1025px) {
	.body {
		.container {
			display: flex;
			flex-direction: row;
			padding-top: 5%;
			padding-bottom: 5%;
			justify-content: space-evenly;

			.posterContainer {
				display: flex;
				flex-direction: column;
				> img {
					align-self: center;
					margin-top: 4%;
				}
				.productsShowcase {
					width: 50vw;
				}
				.buttonContainer {
					padding-top: 1%;
					position: relative; /* Add this if it's not already present */
					z-index: 1; /* Lower z-index to ensure it's behind the swatches */

					.button {
						width: auto;
						height: auto;
						font-family: 'Raleway', sans-serif;

						text-align: center;
						border-radius: 10px;
						font-size: 80%;
						transition: all 0.1s ease;
						cursor: pointer;
						text-decoration: none;

						background-color: rgba(248, 221, 170, 0.2);
						font-weight: 600;
						border: 2px solid rgba(248, 221, 170, 0.8);
						color: black;
					}

					.button:hover {
						background-color: rgba(248, 221, 170, 0.8);
						color: black;
						text-decoration: none;
						transition: none;
					}
				}
			}

			.bodySwatches {
				z-index: 2;
				position: relative;
				display: flex;
				flex-direction: 'row';
				padding-top: 4%;
				align-self: center;
			}

			.detailsPosters {
				padding-top: 10%;
				width: 30vw;
			}
			.gradientDiv {
				transform-style: preserve-3d;
				box-shadow: rgba(209, 204, 176, 0.493) 0px 1px 5px;
				justify-content: center;
				width: 30vw;
				min-height: 80vh;
				font-family: 'Alice', serif;
				font-weight: 400;
				font-style: normal;
				cursor: default;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-self: center;
				z-index: 1;
				position: relative;
			}

			@media screen and (min-width: 1300px) {
				.gradientDiv {
					padding-left: 2%;
					margin-left: 0%;
					width: 30vw;
					min-height: 70vh;
					display: flex;
				}
			}

			.gradientTitle {
				font-size: 3rem;
				padding-top: 5%;
			}

			.frameContainer {
				width: 25vw;
			}

			.titleContainer {
				border-bottom: 0.1ch solid rgba(159, 153, 153, 0.379);
				width: 25vw;
				> h1 {
					font-size: xx-large;
					font-style: italic;
				}
			}

			.descriptionContainer {
				width: 25vw;
				display: flex;
				flex-direction: column;
				align-items: center;

				font-family: 'Raleway', sans-serif;
				text-align: left;

				.paragraphContainer {
					padding-top: 3%;
					text-align: justify;

					> p {
						font-family: 'Raleway', sans-serif;
						padding-top: 5%;
					}
				}

				.buttonContainer {
					padding-top: 5%;
					padding-bottom: 5%;
					.button {
						width: 25vw;
						height: 8vh;
						font-family: 'Raleway', sans-serif;

						text-align: center;
						border-radius: 10px;
						font-size: 100%;
						transition: all 0.1s ease;
						cursor: pointer;
						text-decoration: none;

						background-color: rgba(248, 221, 170, 0.2);
						font-weight: 600;
						border: 2px solid rgba(248, 221, 170, 0.8);
						color: black;
					}

					.button:hover {
						background-color: rgba(248, 221, 170, 0.8);
						color: black;

						text-decoration: none;
						transition: none;
					}
				}

				.detailsContainer {
					align-self: flex-start;
				}
			}

			.current {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.body {
		margin-top: 0%;
		padding-left: 0%;
		margin-left: 0%;
		border-left: 0;
		.container {
			flex-direction: row;
			align-items: center;
			padding-bottom: 5%;
			padding-left: 0;
			padding-right: 0;
			margin-left: auto;
			margin-right: auto;
			font-family: 'Raleway', sans-serif;
			display: flex;
			width: 100vw;

			.buttonContainer {
				padding-top: 1%;
				position: relative; /* Add this if it's not already present */
				z-index: 1; /* Lower z-index to ensure it's behind the swatches */

				.button {
					width: auto;
					height: auto;
					font-family: 'Raleway', sans-serif;

					text-align: center;
					border-radius: 10px;
					font-size: 80%;
					cursor: pointer;
					text-decoration: none;
					transition: all 0.1s ease;
					background-color: rgba(248, 221, 170, 0.2);
					font-weight: 600;
					border: 2px solid rgba(248, 221, 170, 0.8);
					color: black;
				}
			}

			.bodySwatches {
				z-index: 2;
				position: relative;
				display: flex;
				flex-direction: 'row';
				justify-content: center;
			}

			.gradientDiv {
				padding-left: 0%;
				margin-left: 0%;
				border-left: 0%;
				width: 100vw;
				min-height: 60vh;
				font-family: 'Alice', serif;
				font-weight: 400;
				font-style: normal;
				cursor: default;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
			.gradientTitle {
				font-size: 3rem;
				padding-top: 2%;
			}

			.frameContainer {
				margin: 3%;
			}

			.imageSlideCorner {
				transition: all 0.1s ease;

				justify-content: center;
				position: relative;
				width: 7vw;
				height: 15vh;
			}

			@media screen and (min-width: 700px) {
				.gradientDiv {
					padding-left: 2%;
					margin-left: 0%;
					width: 70vw;
					height: 60vh;
					display: flex;
				}
			}

			.columnFlex {
				display: flex;
				flex-direction: column;
			}

			.descriptionContainer {
				font-family: 'Raleway', sans-serif;
				text-align: left;
				justify-content: center;

				.titleContainer {
					margin-left: 3%;

					width: 95vw;
					border-bottom: 0.1ch solid rgba(159, 153, 153, 0.379);
					padding-left: 1%;
					> h1 {
						font-size: xx-large;
						font-style: italic;
					}
				}

				.paragraphContainer {
					text-align: justify;

					padding-top: 5%;
					padding-left: 1%;
					width: 95vw;

					> p {
						font-family: 'Raleway', sans-serif;
					}
				}

				.buttonContainer {
					padding-bottom: 5%;
					display: flex; /* Use flexbox to center the button */
					justify-content: center; /* Center horizontally */
					align-items: center; /* Center vertically (if needed) */
					.button {
						width: 85vw;
						height: 60px;
						font-family: 'Raleway', sans-serif;
						font-size: 100%;
						transition: all 0.1s ease;
						border-radius: 10px;

						cursor: pointer;
						text-decoration: none;
						background-color: rgba(248, 221, 170, 0.2);
						font-weight: 600;
						border: 2px solid rgba(248, 221, 170, 0.8);
						color: black;
					}

					.button:hover {
						text-decoration: none;
						transition: none;
						background-color: rgba(248, 221, 170, 0.8);
					}

					.detailsContainer {
						padding-left: 1%;
					}
				}
			}
		}
	}
}
