.container {
	display: flex;
	flex-direction: row;
	height: 40vh;
	background-color: #fffaed;
	align-items: left;

	.imgContainer {
		position: relative;

		width: 50%;
		height: 100%; // Ensures the container takes up the full height of the parent
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		transition: all 0.05s;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover; // Ensures the image covers the container without distortion
		}
	}

	.text {
		padding-left: 3%;
		margin-right: 2%;
		margin-top: 3%;

		.subheadline {
			text-decoration: none;
			color: black;
			> h2 {
				font-size: 1rem;
				font-family: 'Raleway', sans-serif;
			}
		}

		> p {
			font-size: 1rem;
			font-family: 'Raleway', sans-serif;
		}
	}
}
