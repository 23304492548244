.container {
	width: 100%;
	height: 70%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	border-radius: 20px;
	margin: 0 auto;
	margin-top: 3rem;
	position: relative; // Added to position child elements easily

	.loaderImage {
		animation: spin 2s ease-in-out infinite; // Apply fade animation
	}

	.fadeInText {
		margin-top: 10px;
		animation: fadeIn 5s;
		text-align: center; // Center the text below the image
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
}
