.container {
	height: auto;
	width: 100%;
	display: flex;
	flex-direction: row;

	.fixedContainer {
		position: sticky;
		font-family: 'Raleway', sans-serif;
		display: block;
		.textP {
			font-family: 'Raleway', sans-serif;
		}
	}

	@mixin myContainer($color, $width, $borderColor) {
		width: $width;
		height: auto;
		background-color: $color;
		background-image: $color;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		transition: all 0.05s;
		// height: calc(100vh - 70px);

		border-width: 1px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.image {
			font-family: 'Raleway', sans-serif;
			padding: 5%;

			> img {
				width: 200px;
			}
			.textP {
				font-family: 'Raleway', sans-serif;
				padding-top: 5%;
			}
		}
	}

	@media screen and (min-width: 1025px) {
		.checkoutContainer {
			@include myContainer(rgba(255, 255, 255, 0.615), 58%, rgb(255, 255, 255));
		}
		.shoppingContainer {
			@include myContainer(rgba(248, 221, 170, 0.2), 42%, rgb(180, 235, 0));
		}
	}

	@media screen and (max-width: 1024px) {
		.checkoutContainer {
			@include myContainer(
				rgba(255, 255, 255, 0.615),
				100%,
				rgb(255, 255, 255)
			);
		}
	}
}
