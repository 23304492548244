.clearfix {
	zoom: 1;
}

.container {
	text-align: center;
}
.clearfix:after {
	content: '.';
	clear: both;
	display: block;
	height: 0;
	visibility: hidden;
}
/* end clearfix */

.heading {
	margin-top: 150px;
}

.text {
	margin-top: 3%;
	margin-bottom: 3%;
	text-align: center;
	font-family: 'Raleway', sans-serif;
}

.section-houses {
	margin-left: 3%;
	margin-right: 3%;
	font-family: 'Raleway', sans-serif;
	width: 90vw;
	@media screen and (max-width: 1025px) {
		width: 100vw;
	}
}

.houses-showcase {
	list-style: none;
	margin-left: 3%;
	margin-right: 3%;
	display: flex;
	flex-wrap: wrap;
	margin-top: 0.5rem;
	justify-content: center;
	gap: 3.2rem;

	@media screen and (max-width: 1025px) {
		gap: 0.5rem;
	}
}

.photo {
	overflow: hidden;
	background: #000;
	border-radius: 3%;
	transition: opacity 0.5s, transform 0.5s;
}

.photo:hover {
	transform: scale(1.07);
}
