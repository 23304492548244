.container {
	display: flex;
	flex-direction: column;
	height: auto;
	margin-right: 3%;
	margin-left: 3%;
	word-wrap: break-word;
	> h4 {
		text-align: center;
		padding-top: 3%;
		margin-bottom: 3%;
		font-family: 'Raleway', sans-serif;
	}
	.terms {
		@media screen and (max-width: 1024px) {
			width: 90vw;
		}
		white-space: pre-wrap;

		align-self: center;
		width: 50%;
		padding-left: 3%;
		margin-right: 2%;
		font-family: 'Raleway', sans-serif;
		word-wrap: break-word;
		> h5 {
			padding-top: 3%;

			font-weight: 600;
		}

		.italic {
			font-style: italic;
			font-style: italic;
		}
		> p {
			font-size: 0.9rem;
			font-family: 'Raleway', sans-serif;
		}
	}
}
