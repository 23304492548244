.container {
	display: block;
	padding-top: 3%;
	text-align: center;
	font-family: Raleway, sans-serif;
	width: 100%;
	cursor: default;

	> h3 {
		font-family: 'Frank Ruhl Libre', serif;

		font-optical-sizing: auto;
		color: #000000;
		font-size: 2.5rem;
		font-style: italic;
		text-align: center;
	}

	& .postersList {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@media screen and (max-width: 1024px) {
			align-items: center;
			height: 90vh;
		}
	}

	& .poster {
		margin-bottom: 2%;
		display: block;
		padding: 1%;
		justify-content: center;
		width: calc(33.33% - 10px);
		//animation: fadeIn 0.8s ease forwards;
		//animation-delay: calc(var(--delay) * 0.2s); /* Stagger animation delay */

		@media screen and (max-width: 1024px) {
			width: calc(50% - 10px);
		}
		> a {
			text-decoration: none;
			text-decoration-line: none;
		}
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
			transform: translateY(20px);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
