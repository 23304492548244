.container {
	width: 35vw;
	font-family: Raleway, sans-serif;
	@media screen and (max-width: 1025px) {
		width: 90vw;
	}

	@media screen and (width: 1024px) {
		width: 80vw;
	}

	.hTitle {
		font-size: 1.5rem;
		padding-bottom: 2%;
		padding-top: 3%;

		@media screen and (max-width: 1024px) {
			font-size: 3.5vw;
		}

		@media screen and (max-width: 450px) {
			font-size: 6vw;
		}
	}

	.buttonContainer {
		@media screen and (max-width: 850px) {
			font-size: 3vw;
		}

		@media screen and (max-width: 1024px) {
			height: 5vh;
			font-size: 3vw;
		}

		@media screen and (width: 1024px) {
			height: 6vh;
			font-size: 2vw;
		}

		@media screen and (max-width: 450px) {
			height: 8vh;
			font-size: 5vw;
		}

		padding: 3%;
		border-radius: 8px;
		font-size: 100%;
		width: 100%;
		background-color: rgba(248, 221, 170, 0.2);
		font-weight: 600;
		border: 2px solid rgba(248, 221, 170, 0.8);
		color: black;
	}

	.buttonContainer:hover {
		text-decoration: none;
		transition: none;
		background-color: rgba(248, 221, 170, 0.8);
	}
}
