.container {
	@media screen and (max-width: 1024px) {
		margin-top: 3%;
		width: 90vw;
	}
	.give {
		font-weight: 600;
		font-style: normal;
	}

	> h1 {
		font-family: 'Frank Ruhl Libre', serif;
		font-optical-sizing: auto;
		color: #000000;
		font-size: 2rem;
		font-style: italic;
	}
	> h2 {
		font-family: 'Raleway', sans-serif;
		font-optical-sizing: auto;
		color: #000000;
		font-size: 0.9rem;
		font-weight: 400;
	}

	> h3 {
		font-size: 0.8rem;
		text-align: justify;
		font-weight: 400;
	}
	width: 40vw;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}
