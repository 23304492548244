html {
	font-family: 'Raleway', sans-serif;
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}
.cd__main {
	background: linear-gradient(
		to right,
		#3f2b96,
		#a8c0ff
	) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.text-center {
	text-align: center;
}

.color-white {
	color: #fff;
}

.box-container {
	align-items: center;
	display: flex;
	justify-content: space-around;
	width: 100%;
}

@media screen and (min-width: 1025px) {
	.box-container {
		flex-direction: row;
	}
}

@media screen and (max-width: 1024px) {
	.box-container {
		flex-direction: column;
	}
}

.box-item {
	position: relative;
	-webkit-backface-visibility: hidden;
	width: 350px;
	margin-bottom: 35px;
	max-width: 100%;

	@media screen and (min-width: 1300px) {
		width: 500px;
	}
}

.flip-box {
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
	perspective: 1000px;
	-webkit-perspective: 1000px;
}

.flip-box-front,
.flip-box-back {
	background-size: cover;
	background-position: center;
	border-radius: 8px;
	min-height: 500px;
	-ms-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	-webkit-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	@media screen and (min-width: 1300px) {
		min-height: 700px;
	}
}

.flip-box-front {
	/* -ms-transform: rotateY(0deg);
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg); */
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.flip-box:hover .flip-box-front {
	/* -ms-transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	transform: rotateY(-180deg); */
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.flip-box-back {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	/* -ms-transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg); */
	/* -webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d; */
}

.flip-box:hover .flip-box-back {
	/* -ms-transform: rotateY(0deg);
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg); */
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.flip-box .inner {
	position: absolute;
	left: 0;
	width: 100%;
	padding: 60px;
	-webkit-perspective: inherit;
	perspective: inherit;
	z-index: 2;

	transform: translateY(-50%) translateZ(60px) scale(0.94);
	-webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
	-ms-transform: translateY(-50%) translateZ(60px) scale(0.94);
	top: 50%;

	> a {
		text-decoration: none;
	}
}

.flip-box-header {
	font-size: 34px;
}

.flip-box p {
	font-size: 20px;
	line-height: 1.5em;
}

.flip-box-img {
	margin-top: 25px;
}

.flip-box-button {
	cursor: pointer;
	text-decoration: none;

	margin-top: 25px;
	padding: 15px 20px;
	text-transform: uppercase;
}

.textContainer {
	font-family: Raleway, sans-serif;
	font-size: large;
	font-weight: 500;
	padding-top: 1%;
}

.priceContainer {
	font-family: Raleway, sans-serif;
	font-weight: 500;
	color: gray;
}
