.resumeContainer {
	padding-top: 3%;
	font-family: 'Raleway', sans-serif;
	padding-bottom: 2%;
	border-top: 0.1ch solid rgb(0, 0, 0);
	border-bottom: 0.1ch solid rgb(0, 0, 0);
	font-size: 0.9rem;
	width: 90vw;

	justify-content: center;

	.textContainer {
		display: flex;
		justify-content: space-between;
	}

	.textContainerAfter {
		display: flex;
		justify-content: space-between;
		padding-bottom: 4%;
	}
}
