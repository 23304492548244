.cart {
	position: relative;
	align-items: center;
	justify-content: space-evenly;
	padding: 3%;
	text-decoration: none;
	z-index: 1;
	.hContainer {
		margin-bottom: 3%;
		text-align: center;
		font-size: 3rem;
	}

	.frame {
		font-size: 1rem;
	}

	.artist {
		font-size: 1rem;
		color: rgb(48, 48, 48);
	}

	.discountedPrice {
		text-decoration: line-through;
	}

	.image {
		height: 30%;
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 20%;
	}

	.tableHeader {
		font-size: large;
	}

	.textContainer {
		padding-top: 2%;

		.pTotal {
			position: relative;
			font-style: italic;
			padding-right: 1%;
			@media screen and (width: 1024px) {
			}

			@media screen and (min-width: 1025px) {
				font-size: 45px;
			}

			@media screen and (min-width: 880px) {
				font-size: 35px;
			}
		}

		.pTva {
			position: relative;
			font-style: italic;

			@media screen and (min-width: 1025px) {
				font-size: 20px;
			}
		}
	}

	.buttonContainer {
		position: relative;
		display: flex;
		flex-direction: column;

		font-family: 'Raleway', sans-serif;
		float: right;
		color: black;
		justify-items: space-between;
		.checkoutContainer {
			background-color: rgba(248, 221, 170, 0.2);
			font-weight: 600;
			border: 2px solid rgba(248, 221, 170, 0.8);
			color: black;
			border-radius: 10px;
			transition: all 0.1s ease;

			@media screen and (min-width: 1025px) {
				width: 30vw;
				height: 60px;
				font-size: 20px;
			}
			@media screen and (min-width: 880px) {
				font-size: 20px;
				width: 35vw;
			}
		}

		.checkoutContainer:hover {
			text-decoration: none;
			transition: none;
			background-color: rgba(248, 221, 170, 0.8);
		}

		.shopContainerButton {
			@media screen and (min-width: 880px) {
				width: 35vw;
			}

			width: 30vw;
			font-style: italic;
			text-decoration: none;
			font-size: 20px;
			color: black;
		}
	}
}
