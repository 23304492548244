.container {
	padding-top: 5%;
	justify-content: space-around;
	font-family: 'Raleway', sans-serif;
	padding-bottom: 10%;

	.confirmationContainer {
		display: flex;
		flex-direction: column;
		width: 430px;
		margin-left: auto;
		margin-right: auto;
		> h5 {
			text-align: center;
			padding-top: 3%;
		}

		@media screen and (max-width: 1024px) {
			width: 100vw;
		}

		> p {
			text-align: center;
		}
		> img {
			@media screen and (max-width: 1024px) {
				width: 100vw;
			}
			width: 430px;
			height: max-content;
		}
	}

	.failedOrderContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		> img {
			width: 200px;
			height: max-content;
		}
	}
}
