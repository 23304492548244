.container > p {
	margin-bottom: 3%;
	font-style: italic;
	font-size: 2rem;
	padding-bottom: 3%;
	padding-top: 3%;
}

.image {
	height: 60%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 60%;
}

.discountedPrice {
	font-size: 20px;

	text-decoration: line-through;
}

.artist {
	font-size: 0.8rem;
	color: rgb(48, 48, 48);
}
.title {
	font-size: 1rem;
}

.price {
	font-size: 1rem;
}

.container {
	text-align: center;
}

.cart {
	.checkoutButton {
		width: 85vw;
		height: 60px;
		font-family: 'Raleway', sans-serif;

		font-size: 100%;
		transition: all 0.1s ease;
		border-radius: 10px;

		cursor: pointer;
		text-decoration: none;
		background-color: rgba(248, 221, 170, 0.2);
		font-weight: 600;
		border: 2px solid rgba(248, 221, 170, 0.8);
		color: black;
	}

	.checkoutButton:hover {
		text-decoration: none;
		transition: none;
		background-color: rgba(248, 221, 170, 0.8);
	}
}
