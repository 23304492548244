.container {
	margin-top: 3%;

	> h1 {
		font-family: 'Frank Ruhl Libre', serif;
		font-optical-sizing: auto;
		color: #000000;
		font-size: 2.5rem;
		font-style: italic;
		text-align: center;
		margin-bottom: 3%;
	}

	& .articlesList {
		margin-left: 3%;
		margin-right: 3%;
		display: flex;
		flex-wrap: wrap;
		gap: 2%; // Adds uniform spacing
		justify-content: space-evenly; // Distributes items evenly
	}

	& .article {
		margin-bottom: 2%;
		animation: fadeIn 0.8s ease forwards;
		animation-delay: calc(var(--delay) * 0.2s);

		// Default: 3 articles per row
		width: calc(33.33% - 2%);

		@media (max-width: 1024px) {
			width: calc(50% - 2%); // 2 articles per row on tablets
		}

		@media (max-width: 600px) {
			width: calc(50% - 2%); // 2 articles per row on smartphones
		}

		@media (max-width: 400px) {
			width: 100%; // 1 article per row on very small screens
		}

		> a {
			text-decoration: none;
		}
	}
}
