.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;

	.pictureContainer {
		width: 100%;
		height: 250px; // Adjust height as needed
		background-position: cover; // Ensures images maintain aspect ratio
		transition: all 1s ease;
	}

	.textContainer {
		display: flex;
		flex-direction: column;
		text-align: left;
		margin-top: 3%;

		font-family: 'Raleway';
		font-style: normal;
		font-display: swap;
		font-weight: 500;
		.desc {
			font-size: 0.8rem;
			color: black;
		}
		> h3 {
			color: black;
			font-size: 1rem;
			@media screen and (max-width: 1024px) {
				font-size: 0.9rem;
			}
		}
		> h4 {
			margin-top: 2%;
			font-size: 0.8rem;
			color: gray;
		}
	}
}
