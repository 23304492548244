.container {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	font-family: 'Raleway', sans-serif;

	.homePhoto {
		margin-top: 3%;
	}
	.ourstory {
		margin: 3%;
		width: 50%;
	}
	.text {
		margin-left: 3%;
		margin-top: 3%;
		margin-bottom: 2%;
	}

	.inspoImages {
		margin-left: auto;
		margin-right: auto;
		font-family: 'Raleway', sans-serif;
		width: 90vw;
		text-align: center;
		overflow-x: scroll;
		overflow-y: hidden;
	}

	.inspoImages::-webkit-scrollbar {
		display: none;
	}

	.introductionContainer {
		width: auto;
		margin: auto;
		text-align: center;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		padding: 2rem;
		border-radius: 10px;
		animation: fadeToRight 3s ease forwards;
		align-items: center;
		margin-bottom: 2%;

		.title {
			font-family: 'Frank Ruhl Libre', serif;
			font-optical-sizing: auto;
			color: #fff7e3;
			font-size: 2rem;
			font-style: italic;
			text-align: center;
		}

		.subtitle {
			padding-top: 1%;
			padding-bottom: 2%;
			font-size: 1rem;
			color: #c9ccff;
		}

		.title {
			font-family: 'Frank Ruhl Libre', serif;
			font-optical-sizing: auto;
			color: #fff7e3;
			font-size: 2rem;
			font-style: italic;
			text-align: center;
		}

		/* Smaller screen */
		@media (max-width: 1300px) {
			.title {
				font-size: 2.2rem;
				margin-bottom: 30%;
			}

			.subtitle {
				font-size: 1.5rem;
			}
		}
		.buttonContainer {
			float: bottom;
			text-align: center;
			.button {
				width: auto;
				height: auto;
				font-family: 'Raleway', sans-serif;
				font-size: 1rem;
				transition: all 0.1s ease;
				background-color: #7684ff;
				border-radius: 10px;
				cursor: pointer;
				text-decoration: none;
				border: 0.1px solid #7684ff;

				color: #fff7e3;
				margin-bottom: 3%;
				text-align: center;

				animation: bounce 1s infinite;
			}

			.button:hover {
				text-decoration: none;
				border: 0.1px solid #7684ff;

				transition: none;
				color: #7684ff;
				animation: none;
				background-color: #fff7e3;
			}

			@keyframes bounce {
				0%,
				20%,
				50%,
				80%,
				100% {
					transform: translateY(0);
				}
				40% {
					transform: translateY(-10px);
				}
				60% {
					transform: translateY(-5px);
				}
			}
		}

		/* Keyframes for fade-to-right animation */
		@keyframes fadeToRight {
			0% {
				transform: translateX(0);
				opacity: 0;
			}
			100% {
				transform: translateX(0);
				opacity: 1;
			}
		}
		@media (max-width: 768px) {
			width: 100vw;
			text-align: center;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			border-radius: 10px;
			transition: transform 0.3s ease;
			padding: auto;
			padding: 0;
			height: auto;
			animation: fadeToRight 3s ease forwards;

			.title {
				border-radius: 20px;
				font-optical-sizing: auto;
				font-weight: 500;
				font-style: italic;
				font-size: 2.5rem;
				text-align: center;
				color: #fff7e3;
			}
			> h1 {
				padding-top: 2%;
			}

			.subtitle {
				opacity: 1;
				padding-left: 1%;
				font-family: 'Frank Ruhl Libre', serif;

				font-weight: 500;
				padding-top: 1%;
				font-size: 1.7rem;
				color: #c9ccff;
			}

			.secondSubtitle {
				opacity: 0;
			}

			.buttonContainer {
				margin-bottom: 10%;
				margin-top: auto; /* Push the button container to the bottom */
			}
		}
	}

	.introductionImgContainer {
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		transition: all 0.05s;
		display: flex;
		height: 100vh;
		background-image: url('https://myselfpostersbucket11.s3.eu-central-1.amazonaws.com/wallpaper.webp');

		@media screen and (max-width: 1024px) {
			height: 80vh; /* Height for screens up to 1024px */
		}

		@media screen and (max-width: 768px) {
			background-image: url('https://myselfpostersbucket11.s3.eu-central-1.amazonaws.com/saltwater_wallpaper.webp');
			height: 70vh; /* Height for screens up to 768px */
		}
	}
}

.products {
	padding: 2%;
	margin: 3%;
	width: 90%;
	display: flex;
	flex-direction: row;
	font-family: 'Raleway', sans-serif;
	text-align: center;
	cursor: grab;
}
