.container {
	text-align: center;
	margin-top: 3%;
	margin-bottom: 3%;
	> img {
		border-radius: 20px;
		@media screen and (max-width: 1024px) {
			width: 90vw;
		}
	}
}
