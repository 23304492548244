@import url('https://fonts.googleapis.com/css2?family=Alice&display=swap');

@media screen and (min-width: 1025px) {
	.frameContainer {
		width: 25vw;
		padding: 4%;
	}
}

@media screen and (max-width: 1024px) {
	.frameContainer {
		width: 90vw;
		padding: 4%;
	}
}
