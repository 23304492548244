@media screen and (min-width: 1024px) {
	.container {
		display: flex;
		align-items: center;
		flex-direction: column;
		.promotionTextContainer {
			height: 30px;
			width: 100%;
			position: relative;
			top: 0;
			vertical-align: baseline;
			background-color: #fff7e3;

			display: flex;
			flex-direction: row; //default value
			align-items: center;
			justify-content: space-evenly;
			font-family: 'Raleway', sans-serif;
			text-decoration: none;
			color: rgb(0, 0, 0);
			font-size: 15px;

			padding-top: 1%;

			.socialMediaLogo {
				display: inline-block;
				vertical-align: top;
				list-style: none;
				margin: 0;
				top: 0;
				line-height: 15px;
				color: black;
				position: absolute;
				left: 5px;

				.socialMediaIcons {
					width: 25px;
					height: 25px;
					margin: none;
					border: none;
				}
			}

			> p > a {
				text-decoration: none;
				color: #7684ff;
			}

			.contactText {
				display: flex;
				flex-direction: row;
				list-style: none;
				margin: 0;
				font-weight: 500;
				position: absolute;
				right: 5px;
			}
		}
		.stillPositionContainer {
			.upperContainer {
				width: 100%;
				background-color: rgb(255, 255, 255);
				font-family: 'Raleway', sans-serif;
				display: flex;
				flex-direction: row;
				align-items: center; /* Center items vertically */
				justify-content: space-between; /* Space between items */
				padding-top: 1%;

				> a {
					flex-grow: 1; /* Allow the anchor to grow and take available space */
					display: flex; /* Make the anchor a flex container */
					justify-content: center; /* Center the image horizontally within the anchor */

					> img {
						padding: 1%;
						cursor: pointer;
					}
				}

				.cartContainer {
					display: flex;
					align-items: center; /* Center items within cartContainer vertically */
					.iconSize {
						width: 30px;
					}
				}
			}

			.underContainer:hover .subheader {
				display: block;
			}

			.underContainer {
				position: sticky;
				bottom: auto;
				height: 60px;
				width: 80vw;
				background-color: rgb(255, 255, 255);
				//		border-top: 0.1ch solid rgba(135, 135, 135, 0.144);
				//	border-bottom: 0.1ch solid rgba(135, 135, 135, 0.144);
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-evenly;
				position: relative;
				.subheader {
					display: none;
					position: absolute;
					top: 100%;
					background-color: white;
					width: 100vw;
					border-radius: 5px;
					opacity: 0;
					transform: translateY(-10px);
					transition: opacity 0.5s ease, transform 0.5s ease;
					z-index: 2;
					&.visible {
						opacity: 1;
						transform: translateY(0);
					}
				}

				.link {
					text-decoration: none;
					font-family: 'Raleway', sans-serif;
					color: rgb(0, 0, 0);
					font-weight: 450;
					font-size: 0.9rem;
				}
			}
		}
	}
}
//smartphone size
@media screen and (max-width: 1024px) {
	.promotionTextContainer {
		height: 35px;

		@media screen and (max-width: 280px) {
			height: 45px;
		}

		width: 100%;
		position: relative;
		top: 0;
		padding-top: 1%;
		background-color: #fff7e3;
		display: block;
		font-family: 'Raleway', sans-serif;
		text-align: center;
		text-decoration: none;
		color: rgb(0, 0, 0);
		font-size: 15px;
		font-weight: 500;
		font-family: 'Raleway', sans-serif;

		> p > a {
			text-decoration: none;
			color: #7684ff;
		}
	}

	.stillPositionContainer {
		justify-content: space-between;
		display: flex;
		font-family: 'Raleway', sans-serif;
		align-items: center;
		padding: 0;
		border-bottom: 0.1ch solid rgba(135, 135, 135, 0.144);

		.shoppingButton {
			padding: 15px;
			margin: 0;
			text-decoration: none;
			color: rgb(0, 0, 0);
			.iconSize {
				width: 30px;
			}
		}

		.link {
			text-decoration: none;
			font-family: 'Raleway', sans-serif;
			color: rgb(0, 0, 0);
			font-weight: 400;
			font-size: 18px;
		}

		> drawerlink {
			text-decoration: none;
			font-family: 'Raleway', sans-serif;
			color: rgb(0, 0, 0);
			font-weight: 400;
			font-size: 40px;
		}
		> h1 {
			position: relative;
			left: -25px;
			font-size: 70px;
			display: flex;
			text-align: center;
			font-family: 'Raleway', sans-serif;
			padding: 10%;
		}

		> a {
			> img {
				width: 200px;
				margin-top: 6%;
				margin-bottom: 6%;
				padding: 12px;
				cursor: pointer;
			}
		}

		.socialMediaLogo {
			list-style: none;
			color: pink;
			position: absolute;
			left: 5px;
			display: flex;
			flex-direction: row;
			top: 200px;
			font-family: 'Raleway', sans-serif;

			.socialMediaIcons {
				width: 25px;
				height: 25px;
				margin: none;
				border: none;
			}
		}
	}
}
