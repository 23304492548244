.buttonContainer {
	.buttonStyle {
		border-radius: 10px;
		font-family: 'Raleway', sans-serif;
		text-align: center;
		font-size: 100%;
		transition: all 0.1s ease;
		cursor: pointer;
		text-decoration: none;
		background-color: #fff4e7;
		font-weight: 600;
		border: 2px solid rgb(252, 236, 208);
		color: black;
		text-decoration-line: none;
	}

	.buttonStyle:hover {
		background-color: rgb(252, 236, 208);
		color: black;

		text-decoration: none;
		text-decoration-line: none;
		transition: none;
	}
}

@media screen and (max-width: 1024px) {
	.buttonContainer {
		display: flex; /* Use flexbox to center the button */
		justify-content: center; /* Center horizontally */
		align-items: center; /* Center vertically (if needed) */
		.buttonStyle {
			font-size: 100%;
			transition: all 0.1s ease;
			font-family: 'Raleway', sans-serif;
			border-radius: 10px;

			cursor: pointer;
			background-color: #fff7e3;
			font-weight: 600;
			border: 2px solid rgb(252, 236, 208);
			color: black;
			text-decoration-line: none;
		}

		.buttonStyle:hover {
			text-decoration: none;
			text-decoration-line: none;
			transition: none;
			background-color: rgb(252, 236, 208);
		}
	}
}
