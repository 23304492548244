input,
textarea {
  border: 1px solid #ffffff00;
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 10px;
}

input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-group {
  clear: both;
  margin: 15px 0;
  position: relative;
}

.input-group input[type='button'] {
  background-color: #ffffff00;
  /* min-width: 38px; */
  width: auto;
  transition: all 300ms ease;
}

.input-group .button-minus,
.input-group .button-plus {
  font-weight: bold;
 
  padding: 0;
  /* width: 38px; */
  position: relative;
}

.input-group .quantity-field {
  /* position: relative;
 
  

  text-align: center; */
  display: inline-block;
  font-size: 13px;
  /* margin: 0 0 5px; */
  resize: vertical;
}

.button-plus {
  left: -13px;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
}
