.container {
	margin-bottom: 3%;
	font-family: 'Raleway', sans-serif;
	@media screen and (max-width: 1024px) {
		margin-top: 3%;
	}

	& .headerContainer {
		font-family: 'Raleway', sans-serif;
		display: flex;
		flex-direction: row;
		flex: 10px;
		justify-content: space-between;
		margin-bottom: 1%;

		> h3 {
			font-style: italic;
		}
		& .text {
			color: black;
			text-decoration: underline;
			font-size: 1rem;
		}
	}

	& .buttonContainer {
		display: flex; /* Use flexbox to center the button */
		justify-content: center; /* Center horizontally */
		align-items: center;
		margin-top: 3%;

		@media screen and (max-width: 1024px) {
			margin-top: 10%;
		}

		> a {
			text-decoration: none;
		}
		& .buttonStyle {
			width: 10vw;
			height: auto;
			padding: 5%;

			@media screen and (max-width: 1024px) {
				width: 30vw;
			}
		}
	}
}

.postersContainer {
	& .postersList {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@media screen and (max-width: 1024px) {
			align-items: center;
			height: 90vh;
		}
	}

	& .poster {
		margin-bottom: 2%;
		display: block;
		padding: 1%;
		justify-content: center;
		width: calc(33.33% - 10px);
		//animation: fadeIn 0.8s ease forwards;
		//animation-delay: calc(var(--delay) * 0.2s); /* Stagger animation delay */

		@media screen and (max-width: 1024px) {
			width: calc(50% - 10px);
		}
		> a {
			text-decoration: none;
			text-decoration-line: none;
		}
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
			transform: translateY(20px);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
