html {
	font-family: 'Raleway', sans-serif;
	box-sizing: border-box;
}

.swipeIconContainer {
	position: absolute;
	bottom: 0;
	left: 0;
	> img {
		width: 30px;
		height: 30px;
	}
}

.textCenter {
	text-align: center;
	align-items: center;
	justify-content: center;
}

.boxContainer {
	align-items: center;
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

.boxItem {
	position: relative;
	margin-top: 10px;
	margin-bottom: 10px;
	max-width: 100%;
	padding-right: 5%;
}

.flipBox {
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
	perspective: 1000px;
	-webkit-perspective: 1000px;
}

.flipBoxFront,
.flipBoxBack {
	@media screen and (max-width: 1024px) {
		width: 250px;
		min-height: 350px;
	}
	min-width: 350px;
	background-size: cover;
	background-position: center;
	border-radius: 8px;
	min-height: 500px;
	-ms-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	-webkit-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.flipBoxFront {
	-ms-transform: rotateY(0deg);
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg);
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.flipBox:hover .flipBoxFront {
	-ms-transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.flipBoxBack {
	position: absolute;
	top: 0;
	left: 0;

	-ms-transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.flipBox:hover .flipBoxBack {
	-ms-transform: rotateY(0deg);
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg);
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.flipBox .inner {
	@media screen and (max-width: 1024px) {
		padding: 0px;
	}
	position: absolute;
	left: 0;
	padding: 60px;
	outline: 1px solid transparent;
	-webkit-perspective: inherit;
	perspective: inherit;
	z-index: 2;
	transform: translateY(-50%) translateZ(60px) scale(0.94);
	-webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
	-ms-transform: translateY(-50%) translateZ(60px) scale(0.94);
	top: 50%;
}

.flipBox .nameContainer {
	color: black;
	display: inline-block;
	line-height: 1.5;
	cursor: pointer;
	font-size: 2rem;
	font-weight: bold;
	padding: 15px 50px;
}

.flipBoxHeader {
	@media screen and (max-width: 1024px) {
		font-size: 1.5rem;
	}
	font-size: 2rem;
	border-radius: 5px;
	text-align: center;
	background-color: rgba(248, 221, 170, 0.133);
}

.flipBox p {
	font-size: 1rem;
	line-height: 1.5em;
}

.flipBoxImg {
	margin-top: 25px;
}
