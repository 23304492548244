.limitedTag {
	position: absolute;
	top: 10px;
	left: 10px;
	text-decoration: none;
	background-color: #fff4e7;
	font-weight: 600;
	border: 2px solid rgb(252, 236, 208);
	color: black;
	font-size: 0.8rem;

	text-transform: uppercase;
	padding: 5px 10px;
	border-radius: 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

	@media screen and (max-width: 1024px) {
		font-size: 0.5rem;
	}
}
