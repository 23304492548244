/* visual.images.module.scss */

.container {
	display: flex;
	flex-direction: row;
	min-height: 600px;
	margin: 3%;
}

.firstContainer,
.secondContainer {
	width: 50%;
	height: 80vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	transition: all 0.05s;
}

.firstContainer {
	background-image: url('https://myselfpostersbucket11.s3.eu-central-1.amazonaws.com/sp1.webp');
	margin-right: 2%;
}

.secondContainer {
	background-image: url('https://myselfpostersbucket11.s3.eu-central-1.amazonaws.com/sp2.webp');
}

@media screen and (max-width: 1024px) {
	.container {
		display: flex;
		flex-direction: column;
	}

	.firstContainer,
	.secondContainer {
		width: 95vw;
		height: 50vh;
		margin-bottom: 3%;
	}

	.firstContainer {
		margin-right: 0;
	}
}
