.container {
	margin: 3%;
	display: flex;
	justify-content: space-evenly; /* Distributes them equally */
	align-items: center;
	gap: 2%; /* Adds spacing between containers */
}

.imgContainer {
	flex: 1; /* Makes both containers take equal width */
	max-width: 800px;
	display: flex;
	flex-direction: column;

	> a {
		text-align: center;
		font-family: 'Raleway', sans-serif;
		margin-top: 3%;
		text-decoration: none;
		color: black;
		font-size: 1.2rem;
		margin-left: 3%;
		font-weight: 500;
	}
}

.firstContainer,
.secondContainer {
	width: 100%; /* Ensures full width within imgContainer */
	min-height: 500px; /* Ensures equal height */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	transition: all 0.05s;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 3%;
	margin-right: 3%;
}

.firstContainer {
	background-image: url('https://myselfpostersbucket11.s3.eu-central-1.amazonaws.com/posters.webp');
}

.secondContainer {
	background-image: url('https://myselfpostersbucket11.s3.eu-central-1.amazonaws.com/inspo19.webp');
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
	.container {
		flex-direction: column;
	}

	.firstContainer,
	.secondContainer {
		width: 95vw;
		height: 400px;
		margin: 0;
	}

	.imgContainer {
		margin-bottom: 3%;
		> a {
			margin-left: 0%;
		}
	}
}
