.container {
	@media screen and (max-width: 1024px) {
		flex-direction: column;
		width: 100vw;
		margin-right: 0%;
		margin-left: 0%;
	}
	display: flex;
	flex-direction: row;
	height: auto;
	border-radius: 60px;
	margin-right: 3%;
	margin-left: 3%;
	margin-top: 3%;
	margin-bottom: 3%;
	.imgContainer {
		@media screen and (max-width: 1024px) {
			width: 90vw;
		}
		width: 40%;
		margin-right: 3%;
		margin-left: 3%;
		> p > b {
			color: #7684ff;
		}
	}

	.text {
		@media screen and (max-width: 1024px) {
			width: 95vw;
			padding: 10%;
		}
		align-self: left;
		margin-top: auto;
		margin-bottom: auto;
		width: 50%;
		padding-left: 3%;
		margin-right: 2%;
		> h3 {
			font-family: 'Frank Ruhl Libre', serif;
			font-style: italic;

			color: #7684ff;
		}
		.subheadline {
			@media screen and (max-width: 1024px) {
				text-align: center;
			}
			font-size: 1.1rem;
		}
		> p {
			@media screen and (max-width: 1024px) {
				text-align: center;
			}
			font-size: 1rem;
			font-family: 'Raleway', sans-serif;
		}

		.paragraph {
			@media screen and (min-width: 1025px) {
				text-align: justify;
			}
		}

		.benefits {
			display: flex;
			flex-direction: row;

			> img {
				width: 50px;
				height: auto;
			}
		}
	}
}
