.modal-overlay {
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.5);
	z-index: 2;
}

.modal {
	background: #ffffff;
	padding: 1.5rem;
	border-radius: 8px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
	width: 400px;
	text-align: center;

	@media screen and (max-width: 1024px) {
		width: 80vw;
	}

	.xIcon {
		display: block;
		float: right;
		cursor: pointer;
	}

	h2 {
		font-family: 'Frank Ruhl Libre', serif;
		font-optical-sizing: auto;
		color: #000000;
		font-size: 2.5rem;
		font-style: italic;
		text-align: center;
		margin-top: 5%;
	}

	p {
		font-size: 1rem;
		color: #444;
	}

	.close-button {
		margin-top: 1rem;
		padding: 0.5rem 1rem;
	}
}
