.container {
	@media screen and (max-width: 1023px) {
		display: block;
		width: 90vw;
	}

	width: 30vw;
	display: block;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	font-family: 'Raleway', sans-serif;
	display: flex;
	flex-direction: column;
	.title {
		padding-top: 3%;
		padding-bottom: 3%;
		text-align: center;
	}
}

.desc {
	display: flex;
	flex-direction: column;
	padding-top: 2%;
	padding-left: 5%;
	border-top: 0.4px solid rgba(0, 0, 0, 0.151);
	border-radius: 5px;
	transform-style: preserve-3d;
	> p {
		padding-left: 1%;
	}

	> h4 {
		font-style: italic;
		font-weight: 600;
	}
}
