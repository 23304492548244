.container {
	margin-bottom: 3%;
	position: relative; /* Add this to contain absolute children */

	@media screen and (max-width: 1024px) {
		margin-top: 3%;
	}

	& .headerContainer {
		font-family: 'Raleway', sans-serif;
		display: flex;
		flex-direction: row;
		flex: 10px;
		justify-content: space-between;
		margin-bottom: 1%;

		> h3 {
			font-style: italic;
		}
		& .text {
			color: black;
			text-decoration: underline;
			font-size: 1rem;
		}
	}

	& .buttonContainer {
		display: flex; /* Use flexbox to center the button */
		justify-content: center; /* Center horizontally */
		align-items: center;
		margin-top: 3%;

		@media screen and (max-width: 1024px) {
			margin-top: 10%;
		}

		> a {
			text-decoration: none;
		}
		& .buttonStyle {
			width: 10vw;
			height: auto;
			padding: 5%;

			@media screen and (max-width: 1024px) {
				width: 30vw;
			}
		}
	}
}
