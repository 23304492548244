.container {
	margin-left: auto;
	margin-right: auto;
	font-family: 'Raleway', sans-serif;
	width: 90vw;
	text-align: center;
	@media screen and (min-width: 1300px) {
		display: grid;
	}

	> h3 {
		text-align: left;
		font-style: italic;
		color: #4255ff;
	}

	.title {
		@media screen and (max-width: 1024px) {
			width: 80vw;
		}

		padding-top: 5%;
		padding-bottom: 5%;
		text-align: center;
		width: 50vw;
		margin-left: auto;
		margin-right: auto;
		> h1 {
			font-size: 2.3rem;
		}

		> h2 {
			font-style: italic;
			font-size: 2rem;
		}
		.author {
			font-size: 1.5rem;
			color: gray;
			text-align: right;
		}
	}

	.description {
		@media screen and (max-width: 1024px) {
			width: 90vw;
		}
		width: 70vw;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		> h3 {
			text-align: left;
			font-style: italic;
			color: #4255ff;
		}
		> p {
			text-align: justify;
			> a {
				text-decoration: none;
				font-style: italic;
			}
		}
	}
}

.rowContainer {
	display: flex;
	flex-direction: column;
}

.colors {
	padding-top: 4%;
}

.colorsContainer {
	@media screen and (max-width: 1025px) {
		width: 97vw;
	}
	width: 70vw;
	border-radius: 5%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 3%;
}
.flipcards {
	@media screen and (min-width: 1300px) {
		margin-left: auto;
		margin-right: auto;
	}

	overflow-x: scroll;
	overflow-y: hidden;
}

.flipcards::-webkit-scrollbar {
	display: none;
}

.researchers {
	padding-right: 5%;
}

.desc {
	display: flex;
	flex-direction: column;
	padding-top: 2%;
	border-top: 0.4px solid rgba(0, 0, 0, 0.151);
	border-radius: 5px;
	transform-style: preserve-3d;
	> p {
		padding-left: 1%;
	}

	> h4 {
		font-style: italic;
		font-weight: 600;
	}
}
