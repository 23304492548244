.container {
	display: flex; // Use Flexbox for layout
	flex-direction: column; // Stack items vertically
	align-items: flex-start; // Align items to the left
	position: relative; // Position for absolute children

	.pictureContainer {
		height: auto;
		width: 100%;
		transition: all 1s ease;
	}

	.fadeOut {
		opacity: 0; // Fade out effect
	}

	.fadeIn {
		opacity: 1; // Fade in effect
		position: absolute; // Position above the original image
		top: 0; // Align with original image
		left: 0; // Align with original image
	}

	.textContainer {
		display: flex;
		flex-direction: column;
		text-align: center;
		margin-top: 3%;
		margin-left: auto;
		margin-right: auto;

		> h3 {
			color: black;
			font-size: 1rem;
			@media screen and (max-width: 1024px) {
				font-size: 0.9rem;
			}
			font-weight: 400;
		}
		.price {
			font-size: 0.9rem;
			color: grey;
			font-weight: 400;
		}
		.artist {
			font-size: 0.8rem;
			color: grey;
			font-weight: 400;
		}
	}
}
