.container {
	display: flex;
	flex-direction: column;
	height: auto;
	margin-right: 3%;
	margin-left: 3%;
	word-wrap: break-word;
	margin-bottom: 3%;

	align-items: center;

	> h4 {
		text-align: center;
		padding-top: 3%;
		margin-bottom: 3%;
		font-family: 'Raleway', sans-serif;
	}

	.buttonContainer {
		@media screen and (max-width: 1024px) {
			width: 100vw;
		}
		display: flex;
		flex-direction: row;
		width: 50%;
		justify-content: space-evenly;
		align-self: center;
		.button {
			width: auto;
			height: auto;
			font-family: 'Raleway', sans-serif;
			font-size: 1.3rem;
			transition: all 0.1s ease;
			background-color: #fff7e3;
			cursor: pointer;
			text-decoration: none;
			border-radius: 10px;
			color: black;
		}

		.button:hover {
			text-decoration: none;
			transition: none;
			background-color: rgba(253, 231, 189, 0.6);
		}
	}
}
