.container {
	display: flex;
	flex-direction: column;
	.textContainer {
		height: auto;
		display: flex;
		flex-direction: column;

		.imgContainer {
			@media screen and (max-width: 1024px) {
				width: 100vw;
				height: auto;
			}
			width: 50%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			transition: all 0.05s;
			height: 350px;
			align-self: center;
			display: flex;
			justify-content: center;
			> img {
				align-self: center;

				@media screen and (max-width: 1024px) {
					width: 300px;
					height: auto;
					align-self: center;
				}
				width: 500px;
			}
		}

		.text {
			@media screen and (max-width: 1024px) {
				padding: 5%;
				width: 100vw;
				margin-right: 0%;
				margin-left: 0%;
			}
			padding-top: 3%;
			margin-top: 3%;
			align-self: center;
			width: 50%;
			padding-left: 3%;
			margin-right: 2%;
			font-family: 'Raleway', sans-serif;
			text-align: justify;

			> h2 > h4 {
				padding-top: 3%;
			}
			.subheadline {
				font-size: 1.1rem;
			}
			.headline {
				font-size: 1.2rem;
				.italic {
					font-style: italic;
				}
			}
			> p {
				font-size: 1rem;
				font-family: 'Raleway', sans-serif;
				> b {
					color: #7684ff;
				}
			}
		}
	}
}
