.shippingContainer {
	@media screen and (max-width: 1025px) {
		height: 8vh;
	}

	@media screen and (min-width: 900px) {
		height: 5vh;
	}

	@media screen and (max-width: 400px) {
		height: 7vh;
	}

	@media screen and (max-width: 300px) {
		height: 0.5vh;
	}

	height: 60px;

	// padding: 15px 0 40px 15px;
	display: flex;
	flex-direction: row;
	flex: 5px;
	padding-left: 3%;
	justify-content: space-between;

	.pBold {
		font-weight: 600;
		padding-right: 1%;
	}
}
.titleShipping {
	font-size: x-large;
	padding-bottom: 2%;
	padding-top: 3%;
}

.formContainer {
	border: 2px solid rgba(248, 221, 170, 0.8);
	background-color: rgba(248, 221, 170, 0.2);
	border-radius: 8px;
	padding: 3%;

	> p {
		font-size: 0.8rem;
	}
}

.titleShipping {
	font-size: x-large;
	padding-bottom: 2%;
	padding-top: 3%;
}
