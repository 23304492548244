.container {
	display: block;
	padding-top: 3%;
	text-align: center;
	font-family: Raleway, sans-serif;
	width: 100%;
	cursor: default;

	> h2 {
		@media screen and (max-width: 1024px) {
			width: 90vw;
			font-size: 0.8rem;
		}
		width: 50vw;
		font-size: 0.9rem;
		margin-left: 3%;
		text-align: left;
		margin-bottom: 5%;
		font-optical-sizing: auto;
		color: #000000;
		text-align: justify;
	}

	> h1 {
		font-family: 'Frank Ruhl Libre', serif;
		font-optical-sizing: auto;
		color: #000000;
		font-size: 2rem;
		font-style: italic;
		margin-left: 3%;
		text-align: left;
	}

	& .postersList {
		margin-left: 3%;
		margin-right: 3%;
		display: flex;
		flex-wrap: wrap; // Allow items to wrap
		justify-content: space-between; // Space items evenly
	}

	& .poster {
		width: calc(50% - 10px); // Default to 2 items per row for small screens
		margin-bottom: 2%;
		animation: fadeIn 0.8s ease forwards;
		animation-delay: calc(var(--delay) * 0.2s); /* Stagger animation delay */

		@media (min-width: 768px) {
			// For tablets and small desktops
			width: calc(33.33% - 10px); // 3 items per row
		}

		@media (min-width: 1024px) {
			// For larger desktops
			width: calc(25% - 10px); // 4 items per row
		}

		> a {
			text-decoration: none;
			text-decoration-line: none;
		}
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
			transform: translateY(20px);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
