.container {
	margin-bottom: 3%;
	background-color: #fff7e3;
	width: 100%;
	margin: 0 auto;

	@media screen and (max-width: 1024px) {
		padding: 0 3%;
		width: 100vw;
	}
}

.benefitSlide {
	display: flex !important; /* Ensure each slide centers content */
	justify-content: center;
	align-items: center;
	padding: 20px;
}

.benefits {
	width: 300px;
	text-align: center;
	font-family: 'Raleway', sans-serif;
	padding: 2% 0;
	animation: scroll 10s linear infinite;

	@media screen and (max-width: 1024px) {
		animation-duration: 10s; // Slower scroll on mobile for readability
	}
	h4 {
		color: #7684ff;
		font-size: 1.2rem;
		margin-top: 1rem;
	}
	.pContainer {
		font-size: 1rem;
		margin-top: 0.5rem;
	}
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-100%);
	}
}
