.container {
	@media screen and (max-width: 1024px) {
		width: 100vw;
		min-height: 60vh;

		@media screen and (min-width: 700px) {
			width: 100vw;
			min-height: 60vh;
		}
	}

	@media screen and (min-width: 1025px) {
		width: 50vw;
		min-height: 100vh;
	}

	background-color: rgb(245, 245, 245);
	justify-content: center;
	display: grid;
}

@media screen and (min-width: 1025px) {
	.picturesContainer {
		transform-style: preserve-3d;
		justify-content: center;
		background-size: contain;
		align-self: center;
		width: 50vw;
		min-height: 80vh;
	}
}

@media screen and (max-width: 1024px) {
	.picturesContainer {
		padding-left: 0%;
		margin-left: 0%;
		border-left: 0%;
		width: 100vw;
		height: 60vh;
		object-fit: contain;
		@media screen and (min-width: 700px) {
			width: 100vw;
			min-height: 60vh;
		}
	}
}

//big screens
@media screen and (min-width: 1300px) {
	.picturesContainer {
		padding-left: 2%;
		margin-left: 0%;
		border-left: 0%;
		width: 100vw;
		height: 70vh;
		display: flex;
		object-fit: contain;
	}
}
